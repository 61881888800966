@import url("https://fonts.googleapis.com/css2?family=Newsreader:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* variables */
:root {
  --main-bg-color: #ffffff;
  --second-bg-color: #f9b9e3;
  --third-bg-color: #aaf0ff;
  --fourth-bg-color: #4e4e4e;
  --headline-font: "Bodoni Moda", serif;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* *::after,
::before {
  box-sizing: border-box;
} */

body {
  position: relative;
}

.about-block > img {
  filter: invert(81%) sepia(28%) saturate(557%) hue-rotate(285deg)
    brightness(99%) contrast(96%);
}

a {
  text-decoration: none; /* no underline */
  color: black;
}

small a {
  text-decoration: none;
  color: #f9b9e3;
}

small {
  font-style: italic;
}
/* 
p {
  font-family: var(--font-family-sans-serif);
} */

.section-header {
  font-size: 2rem;
}

button {
  /* color: var(--fourth-bg-color); */
  background-color: #00000000;
  border: 2px solid #000000;
  padding: 1rem;
  text-align: center;
  text-decoration: none;
  /* font-style: italic; */
  /* font-weight: 600; */
  display: inline-block;
  font-size: 1rem;
  border-radius: 10px;
  cursor: pointer;
  /* text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.243); */
  /* filter: drop-shadow(0px 0px 2px rgb(11, 56, 111, 0.3)); */
  /* box-shadow: inset 5px 5px 0px rgb(0 0 0 / 25%); */
  margin-top: 2rem;
  transition: all 200ms;
  /* color: var(--fourth-bg-color); */
}

button:hover {
  /* outline-offset: 5px; */
  background-color: #000000;
  color: white;
}

.about-block {
  margin: auto;
  padding: 2rem;
  text-align: center;
}

.hero-block {
  grid-column: span 1;
  margin: auto;
}

/* .hero-block#last { */
/* background-color: rgb(255, 255, 255); */
/* padding: 3rem;
  border: #f9b9e3 solid; */
/* background: linear-gradient(rgb(0,0,0)) */
/* } */

h1 {
  font-size: 3rem;
}

.hero-block h1,
.hero-block p {
  margin: auto;
}

h1,
h2 {
  font-family: var(--headline-font);
}

.location-block {
  padding: 2rem;
  grid-column: span 1;
  margin: auto;
}

.testimonial-block {
  grid-column: 2;
  padding: 2rem;
  margin: auto;
}

.testimonial-block > p {
  text-align: center;
}

.footer-block {
  grid-column: span 1;
  padding: 2rem;
  max-width: 100%;
  /* margin: auto; */
}

.footer-credit {
  grid-row: span 1;
}

.footer-links {
  list-style-type: none; /* remove bullets */
  padding: 0;
  margin: 0%;
}

.footer-links a {
  color: white;
}

.footer-links li {
  padding-bottom: 30px;
}

img {
  max-width: 100%;
  height: auto;
}

#testimonials-section {
  min-height: 400px;
  background: var(--third-bg-color);
  box-shadow: inset 0px 4px 34px rgba(0, 0, 0, 0.25);
  display: grid;
  grid-template-columns: auto 1fr auto;
}

#testimonials-section h1 {
  padding: 2rem;
}

#hero-section .hero-text {
  padding: 2rem;
  padding-right: 10rem;
  /* color: var(--fourth-bg-color); */
  text-shadow: 0px 0px 2rem white;
  /* background-color: #f9b9e3; */
  /* background-color: #67214e; */
}

.about-block > p {
  font-style: italic;
  font-size: 1.1em;
  max-width: 225px;
}

.about-block {
  max-height: 300px;
}

.about-block > h2 {
  font-size: 2em;
}

#hero-section {
  display: grid;
  min-height: 700px;
  grid-template-columns: 1fr 1fr;
  background-image: linear-gradient(
      rgba(255, 129, 215, 0.5),
      rgb(255, 255, 255)
    ),
    url("./resources/hero.jpg");
  /* filter: blur(8px); */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  font-weight: 400;
}

#hero-section {
  /* background-image: linear-gradient(
      rgba(255, 129, 215, 0.5),
      rgba(255, 255, 0, 0.318)
    ),
    url("./resources/hero.jpg"); */
  /* background-size: 100%; */
  /* background-color: #000000;
  background-image: linear-gradient(rgba(255, 129, 215, 0.5)),
    url("./resources/hero.jpg");
  grid-column: initial; */
  /* filter: blur(8px); */
}

#locations-section {
  display: grid;
  min-height: 400px;
  grid-template-columns: 1fr 1fr;
}

#appointment-section {
  display: grid;
  min-height: 400px;
  grid-template-columns: 1fr 1fr;
}

#footer-section {
  display: grid;
  min-height: 10rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr auto;
}

#appointment-section img {
  /* padding: 2rem; */
  size: 80%;
}

#last > div > h1 {
  font-size: 4rem;
  padding-right: 2rem;
}

/* main on smaller screens */
@media only screen and (max-width: 600px) {
  #hero-section .hero-text {
    padding-right: 0;
    margin: 10px;
  }

  #about-section {
    padding-bottom: 2em;
  }

  #hero-section {
    grid-template-columns: 1fr;
  }

  .section-header {
    font-size: 8vw;
  }

  /* #hero-section > div.hero-block.hero-text > h1 {
    font-size: 12vw !important ;
  } */

  #last > div > h1 {
    font-size: 12vw !important ;
  }

  #locations-section {
    grid-template-columns: 1fr;
    size: 50%;
  }

  #appointment-section {
    grid-template-columns: 1fr;
    size: 50%;
  }

  #footer-section {
    grid-template-columns: 1fr;
  }

  .leaflet-container {
    min-height: inherit;
  }

  #location-copy {
    order: 1;
  }

  #location-map {
    order: 2;
  }
}

.all-columns {
  grid-column: 1 / 6;
}
