@import url(https://fonts.googleapis.com/css2?family=Newsreader:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#mapid {
  height: 180px;
}

.leaflet-container {
  grid-column: span 1;
  margin: 2em;
  border: 1px solid black;
}

/* variables */
:root {
  --main-bg-color: #ffffff;
  --second-bg-color: #f9b9e3;
  --third-bg-color: #aaf0ff;
  --fourth-bg-color: #4e4e4e;
  --headline-font: "Bodoni Moda", serif;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* *::after,
::before {
  box-sizing: border-box;
} */

body {
  position: relative;
}

.about-block > img {
  -webkit-filter: invert(81%) sepia(28%) saturate(557%) hue-rotate(285deg)
    brightness(99%) contrast(96%);
          filter: invert(81%) sepia(28%) saturate(557%) hue-rotate(285deg)
    brightness(99%) contrast(96%);
}

a {
  text-decoration: none; /* no underline */
  color: black;
}

small a {
  text-decoration: none;
  color: #f9b9e3;
}

small {
  font-style: italic;
}
/* 
p {
  font-family: var(--font-family-sans-serif);
} */

.section-header {
  font-size: 2rem;
}

button {
  /* color: var(--fourth-bg-color); */
  background-color: #00000000;
  border: 2px solid #000000;
  padding: 1rem;
  text-align: center;
  text-decoration: none;
  /* font-style: italic; */
  /* font-weight: 600; */
  display: inline-block;
  font-size: 1rem;
  border-radius: 10px;
  cursor: pointer;
  /* text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.243); */
  /* filter: drop-shadow(0px 0px 2px rgb(11, 56, 111, 0.3)); */
  /* box-shadow: inset 5px 5px 0px rgb(0 0 0 / 25%); */
  margin-top: 2rem;
  transition: all 200ms;
  /* color: var(--fourth-bg-color); */
}

button:hover {
  /* outline-offset: 5px; */
  background-color: #000000;
  color: white;
}

.about-block {
  margin: auto;
  padding: 2rem;
  text-align: center;
}

.hero-block {
  grid-column: span 1;
  margin: auto;
}

/* .hero-block#last { */
/* background-color: rgb(255, 255, 255); */
/* padding: 3rem;
  border: #f9b9e3 solid; */
/* background: linear-gradient(rgb(0,0,0)) */
/* } */

h1 {
  font-size: 3rem;
}

.hero-block h1,
.hero-block p {
  margin: auto;
}

h1,
h2 {
  font-family: "Bodoni Moda", serif;
  font-family: var(--headline-font);
}

.location-block {
  padding: 2rem;
  grid-column: span 1;
  margin: auto;
}

.testimonial-block {
  grid-column: 2;
  padding: 2rem;
  margin: auto;
}

.testimonial-block > p {
  text-align: center;
}

.footer-block {
  grid-column: span 1;
  padding: 2rem;
  max-width: 100%;
  /* margin: auto; */
}

.footer-credit {
  grid-row: span 1;
}

.footer-links {
  list-style-type: none; /* remove bullets */
  padding: 0;
  margin: 0%;
}

.footer-links a {
  color: white;
}

.footer-links li {
  padding-bottom: 30px;
}

img {
  max-width: 100%;
  height: auto;
}

#testimonials-section {
  min-height: 400px;
  background: #aaf0ff;
  background: var(--third-bg-color);
  box-shadow: inset 0px 4px 34px rgba(0, 0, 0, 0.25);
  display: grid;
  grid-template-columns: auto 1fr auto;
}

#testimonials-section h1 {
  padding: 2rem;
}

#hero-section .hero-text {
  padding: 2rem;
  padding-right: 10rem;
  /* color: var(--fourth-bg-color); */
  text-shadow: 0px 0px 2rem white;
  /* background-color: #f9b9e3; */
  /* background-color: #67214e; */
}

.about-block > p {
  font-style: italic;
  font-size: 1.1em;
  max-width: 225px;
}

.about-block {
  max-height: 300px;
}

.about-block > h2 {
  font-size: 2em;
}

#hero-section {
  display: grid;
  min-height: 700px;
  grid-template-columns: 1fr 1fr;
  background-image: linear-gradient(
      rgba(255, 129, 215, 0.5),
      rgb(255, 255, 255)
    ),
    url(/static/media/hero.22bf7e6e.jpg);
  /* filter: blur(8px); */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  font-weight: 400;
}

#hero-section {
  /* background-image: linear-gradient(
      rgba(255, 129, 215, 0.5),
      rgba(255, 255, 0, 0.318)
    ),
    url("./resources/hero.jpg"); */
  /* background-size: 100%; */
  /* background-color: #000000;
  background-image: linear-gradient(rgba(255, 129, 215, 0.5)),
    url("./resources/hero.jpg");
  grid-column: initial; */
  /* filter: blur(8px); */
}

#locations-section {
  display: grid;
  min-height: 400px;
  grid-template-columns: 1fr 1fr;
}

#appointment-section {
  display: grid;
  min-height: 400px;
  grid-template-columns: 1fr 1fr;
}

#footer-section {
  display: grid;
  min-height: 10rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr auto;
}

#appointment-section img {
  /* padding: 2rem; */
  size: 80%;
}

#last > div > h1 {
  font-size: 4rem;
  padding-right: 2rem;
}

/* main on smaller screens */
@media only screen and (max-width: 600px) {
  #hero-section .hero-text {
    padding-right: 0;
    margin: 10px;
  }

  #about-section {
    padding-bottom: 2em;
  }

  #hero-section {
    grid-template-columns: 1fr;
  }

  .section-header {
    font-size: 8vw;
  }

  /* #hero-section > div.hero-block.hero-text > h1 {
    font-size: 12vw !important ;
  } */

  #last > div > h1 {
    font-size: 12vw !important ;
  }

  #locations-section {
    grid-template-columns: 1fr;
    size: 50%;
  }

  #appointment-section {
    grid-template-columns: 1fr;
    size: 50%;
  }

  #footer-section {
    grid-template-columns: 1fr;
  }

  .leaflet-container {
    min-height: inherit;
  }

  #location-copy {
    order: 1;
  }

  #location-map {
    order: 2;
  }
}

.all-columns {
  grid-column: 1 / 6;
}

.main-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: auto;
  max-width: 100%;
}

.main-section {
  grid-column: span 5;
  grid-row: span 1;
  width: 100%;
  min-height: 500px;
  /* height: fit-content; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* padding: 2rem; */
  background: var(--main-bg-color);
}

/* grid on smaller screens */
@media only screen and (max-width: 500px) {
  .main-section {
    /* padding: 2rem; */
    grid-template-columns: 1fr;
    grid-column: span 5;
  }
}

.footer {
  grid-column: span 5;
  grid-row: span 1;
  background-color: var(--fourth-bg-color);
  padding: 2rem;
  color: var(--main-bg-color);
  font-weight: normal;
  -webkit-font-feature-settings: "smcp", "c2sc";
          font-feature-settings: "smcp", "c2sc";
  font-variant-caps: all-small-caps;
  /* overflow: hidden; */
  word-break: normal;
  /* overflow-wrap: break-word; */
}

/* .footer > {
  word-break: normal !important;
} */

/* main on smaller screens */
@media only screen and (max-width: 400px) {
  .footer {
    /* grid-column: span 1; */
    /* width: 100%; */
    /* text-align: center; */
    font-size: larger;
    word-break: break-all;
  }
}

.navbar {
  /* grid-column: span 5;
  grid-row: span 1; */
  align-items: center;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0.5rem;
  text-align: center;
  /* border-bottom: 2px solid var(--second-bg-color); */
  /* filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.25)); */
  font-weight: normal;
  -webkit-font-feature-settings: "smcp", "c2sc";
          font-feature-settings: "smcp", "c2sc";
  font-variant-caps: all-small-caps;
}

nav {
  grid-column: span 5;
  grid-row: auto;
  border-bottom: 2px solid var(--second-bg-color);
}

.navbar img {
  margin: auto;
  height: 50px !important;
}

#nav-logo {
  min-width: 50px;
  max-width: 50px;
  -webkit-filter: invert(81%) sepia(28%) saturate(557%) hue-rotate(285deg)
    brightness(99%) contrast(96%);
          filter: invert(81%) sepia(28%) saturate(557%) hue-rotate(285deg)
    brightness(99%) contrast(96%);
}

a {
  position: relative;
  font-weight: 500;
  cursor: pointer;
}

.navbar a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -8px;
  left: 0;
  background-color: black;
  visibility: hidden;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

/*
  no effect on logo link
*/
.navbar #link-logo:before {
  height: 0px;
}

.navbar span a:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.sidebar div:hover {
  background-color: var(--second-bg-color);
}

.sidebar div {
  padding: 0.75rem;
}

.hamburger > div {
  width: 35px;
  height: 5px;
  background-color: black;
  margin: 6px 0;
  transition: 0.5s;
}

.hamburger:active > div {
  background-color: var(--second-bg-color);
  transition: 0s;
}

.hamburger {
  padding: 0.75rem;
  display: inline-block;
  /* display: none; */
  position: absolute;
  cursor: pointer;
}

.mobile-logo {
  padding: 1rem;
  text-align: center;
  /* margin-bottom: 1rem; */
}

.ham-logo {
  /* display: grid; */
  display: none;
  grid-template-columns: 1fr;
}
.sidebar {
  /* display: none; */
  max-height: 0;
  -webkit-transform: translate(0);
          transform: translate(0);
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  -webkit-font-feature-settings: "smcp", "c2sc";
          font-feature-settings: "smcp", "c2sc";
  font-variant-caps: all-small-caps;
}

/* .sidebar:hover {
  transform: translate(0);
} */

.small-expand-false {
  max-height: 0;
  transition: 0.25s;
  overflow: hidden;
}

.small-expand-true {
  overflow: hidden;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  max-height: 100px;
  transition: 0.25s;
}

.small-expand a:active {
  color: white;
}

@media only screen and (max-width: 750px) {
  .ham-logo {
    display: grid;
  }

  /* .sidebar {
    display: none;
    height: 0;
    transition: height 0.5s ease-in-out;
  } */

  .false-hidden {
    display: block;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    max-height: 500px;
  }
  .true-hidden {
    /* display: none; */
    /* transform: scaleY(0); */
    max-height: 0;
  }
  /* .navbar span a {
    display: none;
  } */
  .navbar {
    display: none;
  }
  .navbar #link-logo {
    display: block;
  }
}

#cycle-testimonial::after {
  /* -webkit-animation: cycle 4s infinite;
  -moz-animation: cycle 4s infinite;
  -o-animation: cycle 4s infinite; */
  -webkit-animation: cycle ease-in-out 30s infinite;
          animation: cycle ease-in-out 30s infinite;
  content: '"My skin has been the best!"';
  padding-right: 1rem;
  font-size: 3rem;
  /* margin-left: 1rem;
  margin-right: 1rem; */
  /* transition: all 1s; */
}

@-webkit-keyframes cycle {
  0% {
    opacity: 1;
    content: '"My skin has been the best!"';
  }
  6% {
    opacity: 0;
  }
  12% {
    opacity: 1;
    content: '"Most painless and quick Brazilian in CT."';
  }
  18% {
    opacity: 0;
  }
  24% {
    opacity: 1;
    content: '"She made me feel comfortable and made sure that I felt safe."';
  }
  30% {
    opacity: 0;
  }
  36% {
    opacity: 1;
    content: '"Loved my facial! Can’t wait for my next visit!"';
  }
  42% {
    opacity: 0;
  }
  48% {
    opacity: 1;
    content: '"Super friendly and does an amazing job!"';
  }
  54% {
    opacity: 0;
  }
  60% {
    opacity: 1;
    content: '"Quickest wax I’ve ever gotten…In & out!"';
  }
  66% {
    opacity: 0;
  }
  72% {
    opacity: 1;
    content: '"I always have a great experience, I love it here"';
  }
  78% {
    opacity: 0;
  }
  84% {
    opacity: 1;
    content: '"The Oxygen Facial w/ LED Light Therapy was everything!! My skin was glowing"';
  }
  90% {
    opacity: 0;
  }
  96% {
    opacity: 1;
    content: '"My skin has been the best!"';
  }
}

@keyframes cycle {
  0% {
    opacity: 1;
    content: '"My skin has been the best!"';
  }
  6% {
    opacity: 0;
  }
  12% {
    opacity: 1;
    content: '"Most painless and quick Brazilian in CT."';
  }
  18% {
    opacity: 0;
  }
  24% {
    opacity: 1;
    content: '"She made me feel comfortable and made sure that I felt safe."';
  }
  30% {
    opacity: 0;
  }
  36% {
    opacity: 1;
    content: '"Loved my facial! Can’t wait for my next visit!"';
  }
  42% {
    opacity: 0;
  }
  48% {
    opacity: 1;
    content: '"Super friendly and does an amazing job!"';
  }
  54% {
    opacity: 0;
  }
  60% {
    opacity: 1;
    content: '"Quickest wax I’ve ever gotten…In & out!"';
  }
  66% {
    opacity: 0;
  }
  72% {
    opacity: 1;
    content: '"I always have a great experience, I love it here"';
  }
  78% {
    opacity: 0;
  }
  84% {
    opacity: 1;
    content: '"The Oxygen Facial w/ LED Light Therapy was everything!! My skin was glowing"';
  }
  90% {
    opacity: 0;
  }
  96% {
    opacity: 1;
    content: '"My skin has been the best!"';
  }
}

#cycle-name::after {
  -webkit-animation: cycle-names ease-in-out 30s infinite;
          animation: cycle-names ease-in-out 30s infinite;
  content: "- Avolyn";
  padding-right: 1rem;
  /* font-size: 2rem; */
}

@-webkit-keyframes cycle-names {
  0% {
    opacity: 1;
    content: "- Avolyn";
  }
  6% {
    opacity: 0;
  }
  12% {
    opacity: 1;
    content: "- Crystal";
  }
  18% {
    opacity: 0;
  }
  24% {
    opacity: 1;
    content: "- Janeese";
  }
  30% {
    opacity: 0;
  }
  36% {
    opacity: 1;
    content: "- Angelica";
  }
  42% {
    opacity: 0;
  }
  48% {
    opacity: 1;
    content: "- Zanah";
  }
  54% {
    opacity: 0;
  }
  60% {
    opacity: 1;
    content: "- Kayla";
  }
  66% {
    opacity: 0;
  }
  72% {
    opacity: 1;
    content: "- Deonna";
  }
  78% {
    opacity: 0;
  }
  84% {
    opacity: 1;
    content: "- Jaela";
  }
  90% {
    opacity: 0;
  }
  96% {
    opacity: 1;
    content: "- Avolyn";
  }
}

@keyframes cycle-names {
  0% {
    opacity: 1;
    content: "- Avolyn";
  }
  6% {
    opacity: 0;
  }
  12% {
    opacity: 1;
    content: "- Crystal";
  }
  18% {
    opacity: 0;
  }
  24% {
    opacity: 1;
    content: "- Janeese";
  }
  30% {
    opacity: 0;
  }
  36% {
    opacity: 1;
    content: "- Angelica";
  }
  42% {
    opacity: 0;
  }
  48% {
    opacity: 1;
    content: "- Zanah";
  }
  54% {
    opacity: 0;
  }
  60% {
    opacity: 1;
    content: "- Kayla";
  }
  66% {
    opacity: 0;
  }
  72% {
    opacity: 1;
    content: "- Deonna";
  }
  78% {
    opacity: 0;
  }
  84% {
    opacity: 1;
    content: "- Jaela";
  }
  90% {
    opacity: 0;
  }
  96% {
    opacity: 1;
    content: "- Avolyn";
  }
}

