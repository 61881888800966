.navbar {
  /* grid-column: span 5;
  grid-row: span 1; */
  align-items: center;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  height: fit-content;
  padding: 0.5rem;
  text-align: center;
  /* border-bottom: 2px solid var(--second-bg-color); */
  /* filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.25)); */
  font-weight: normal;
  font-variant-caps: all-small-caps;
}

nav {
  grid-column: span 5;
  grid-row: auto;
  border-bottom: 2px solid var(--second-bg-color);
}

.navbar img {
  margin: auto;
  height: 50px !important;
}

#nav-logo {
  min-width: 50px;
  max-width: 50px;
  filter: invert(81%) sepia(28%) saturate(557%) hue-rotate(285deg)
    brightness(99%) contrast(96%);
}

a {
  position: relative;
  font-weight: 500;
  cursor: pointer;
}

.navbar a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -8px;
  left: 0;
  background-color: black;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

/*
  no effect on logo link
*/
.navbar #link-logo:before {
  height: 0px;
}

.navbar span a:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.sidebar div:hover {
  background-color: var(--second-bg-color);
}

.sidebar div {
  padding: 0.75rem;
}

.hamburger > div {
  width: 35px;
  height: 5px;
  background-color: black;
  margin: 6px 0;
  transition: 0.5s;
}

.hamburger:active > div {
  background-color: var(--second-bg-color);
  transition: 0s;
}

.hamburger {
  padding: 0.75rem;
  display: inline-block;
  /* display: none; */
  position: absolute;
  cursor: pointer;
}

.mobile-logo {
  padding: 1rem;
  text-align: center;
  /* margin-bottom: 1rem; */
}

.ham-logo {
  /* display: grid; */
  display: none;
  grid-template-columns: 1fr;
}
.sidebar {
  /* display: none; */
  max-height: 0;
  transform: translate(0);
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  font-variant-caps: all-small-caps;
}

/* .sidebar:hover {
  transform: translate(0);
} */

.small-expand-false {
  max-height: 0;
  transition: 0.25s;
  overflow: hidden;
}

.small-expand-true {
  overflow: hidden;
  transform: scaleY(1);
  max-height: 100px;
  transition: 0.25s;
}

.small-expand a:active {
  color: white;
}

@media only screen and (max-width: 750px) {
  .ham-logo {
    display: grid;
  }

  /* .sidebar {
    display: none;
    height: 0;
    transition: height 0.5s ease-in-out;
  } */

  .false-hidden {
    display: block;
    transform: scaleY(1);
    max-height: 500px;
  }
  .true-hidden {
    /* display: none; */
    /* transform: scaleY(0); */
    max-height: 0;
  }
  /* .navbar span a {
    display: none;
  } */
  .navbar {
    display: none;
  }
  .navbar #link-logo {
    display: block;
  }
}
