#cycle-testimonial::after {
  /* -webkit-animation: cycle 4s infinite;
  -moz-animation: cycle 4s infinite;
  -o-animation: cycle 4s infinite; */
  animation: cycle ease-in-out 30s infinite;
  content: '"My skin has been the best!"';
  padding-right: 1rem;
  font-size: 3rem;
  /* margin-left: 1rem;
  margin-right: 1rem; */
  /* transition: all 1s; */
}

@keyframes cycle {
  0% {
    opacity: 1;
    content: '"My skin has been the best!"';
  }
  6% {
    opacity: 0;
  }
  12% {
    opacity: 1;
    content: '"Most painless and quick Brazilian in CT."';
  }
  18% {
    opacity: 0;
  }
  24% {
    opacity: 1;
    content: '"She made me feel comfortable and made sure that I felt safe."';
  }
  30% {
    opacity: 0;
  }
  36% {
    opacity: 1;
    content: '"Loved my facial! Can’t wait for my next visit!"';
  }
  42% {
    opacity: 0;
  }
  48% {
    opacity: 1;
    content: '"Super friendly and does an amazing job!"';
  }
  54% {
    opacity: 0;
  }
  60% {
    opacity: 1;
    content: '"Quickest wax I’ve ever gotten…In & out!"';
  }
  66% {
    opacity: 0;
  }
  72% {
    opacity: 1;
    content: '"I always have a great experience, I love it here"';
  }
  78% {
    opacity: 0;
  }
  84% {
    opacity: 1;
    content: '"The Oxygen Facial w/ LED Light Therapy was everything!! My skin was glowing"';
  }
  90% {
    opacity: 0;
  }
  96% {
    opacity: 1;
    content: '"My skin has been the best!"';
  }
}

#cycle-name::after {
  animation: cycle-names ease-in-out 30s infinite;
  content: "- Avolyn";
  padding-right: 1rem;
  /* font-size: 2rem; */
}

@keyframes cycle-names {
  0% {
    opacity: 1;
    content: "- Avolyn";
  }
  6% {
    opacity: 0;
  }
  12% {
    opacity: 1;
    content: "- Crystal";
  }
  18% {
    opacity: 0;
  }
  24% {
    opacity: 1;
    content: "- Janeese";
  }
  30% {
    opacity: 0;
  }
  36% {
    opacity: 1;
    content: "- Angelica";
  }
  42% {
    opacity: 0;
  }
  48% {
    opacity: 1;
    content: "- Zanah";
  }
  54% {
    opacity: 0;
  }
  60% {
    opacity: 1;
    content: "- Kayla";
  }
  66% {
    opacity: 0;
  }
  72% {
    opacity: 1;
    content: "- Deonna";
  }
  78% {
    opacity: 0;
  }
  84% {
    opacity: 1;
    content: "- Jaela";
  }
  90% {
    opacity: 0;
  }
  96% {
    opacity: 1;
    content: "- Avolyn";
  }
}
