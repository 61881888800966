.footer {
  grid-column: span 5;
  grid-row: span 1;
  background-color: var(--fourth-bg-color);
  padding: 2rem;
  color: var(--main-bg-color);
  font-weight: normal;
  font-variant-caps: all-small-caps;
  /* overflow: hidden; */
  word-break: normal;
  /* overflow-wrap: break-word; */
}

/* .footer > {
  word-break: normal !important;
} */

/* main on smaller screens */
@media only screen and (max-width: 400px) {
  .footer {
    /* grid-column: span 1; */
    /* width: 100%; */
    /* text-align: center; */
    font-size: larger;
    word-break: break-all;
  }
}
