.main-section {
  grid-column: span 5;
  grid-row: span 1;
  width: 100%;
  min-height: 500px;
  /* height: fit-content; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* padding: 2rem; */
  background: var(--main-bg-color);
}

/* grid on smaller screens */
@media only screen and (max-width: 500px) {
  .main-section {
    /* padding: 2rem; */
    grid-template-columns: 1fr;
    grid-column: span 5;
  }
}
